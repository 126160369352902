import type * as pt from "@prismicio/types"
import { getGatsbyImageData } from "@imgix/gatsby"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyRouteFeatures } from "../../../slices/PageDataBodyRouteFeatures"

type Landmark = React.ComponentPropsWithoutRef<
	typeof PageDataBodyRouteFeatures
>["landmarks"][number]

const getSlideImage = (data: { src: string; aspectRatio: number }) => {
	return getGatsbyImageData({
		width: 800,
		layout: "fullWidth",
		...data,
	})
}

const landmark = (data: Partial<Landmark> = {}): Landmark => {
	return {
		image: getSlideImage({
			src: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
			aspectRatio: 1513 / 1008,
		}),
		alt: "",
		description: [
			{ text: lorem.sentences(1), spans: [], type: "paragraph" },
		] as pt.RichTextField,
		title: lorem.words(3),
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyRouteFeatures
				heading={lorem.words(3)}
				copy={
					[
						{ text: lorem.paragraphs(1), type: "paragraph", spans: [] },
					] as pt.RichTextField
				}
				feature1Number={1}
				feature1Alt=""
				feature1Heading={lorem.words(1)}
				feature1Image={getGatsbyImageData({
					src: "https://images.unsplash.com/photo-1516383651701-61f38b096ab9",
					width: 800,
					layout: "fullWidth",
					aspectRatio: 891 / 668,
				})}
				feature2Number={2}
				feature2Alt=""
				feature2Heading={lorem.words(1)}
				feature2Image={getGatsbyImageData({
					src: "https://images.unsplash.com/photo-1506567859980-33c9c423649f",
					width: 800,
					layout: "fullWidth",
					aspectRatio: 755 / 1007,
				})}
				feature3Number={3}
				feature3Alt=""
				feature3Heading={lorem.words(1)}
				feature3Image={getGatsbyImageData({
					src: "https://images.unsplash.com/photo-1547890316-3bb90edb9004",
					width: 800,
					layout: "fullWidth",
					aspectRatio: 755 / 1007,
				})}
				routeLink="#"
				routeLinkTarget={undefined}
				routeLinkText="Learn More"
				landmarks={[landmark(), landmark(), landmark()]}
			/>
		</CmsGuideLayout>
	)
}

export default Component
